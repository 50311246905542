import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { createGlobalStyle } from 'styled-components'
import { Reset } from 'styled-reset'
import { CookieConsentsProvider} from "@enzsft/react-cookie-consents"

import "animate.css/animate.min.css"
import { GREY_25 } from './config'
import CookieBanner from './CookieBanner'

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css?family=Muli:400,600,700');

  body {
    font-family: 'Muli', sans-serif;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: ${GREY_25};
  }

  strong {
    font-weight: 700;
  }
`

const Layout = ({ children }) => (
  <React.Fragment>
    <Reset />
    <link rel="stylesheet" href="https://use.fontawesome.com/releases/v5.8.1/css/all.css" integrity="sha384-50oBUHEmvpQ+1lW4y57PTFmhCaXp0ML5d60M1M7uH2+nqUivzIebhndOJK28anvf" crossOrigin="anonymous" />
    <GlobalStyle />
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={data => (
        <>
          {children}
        </>
      )}
    />
    <CookieConsentsProvider cookieName="cookieConsents" expiryInDays={365}>
      <CookieBanner />
    </CookieConsentsProvider>
  </React.Fragment>
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
