import React from "react"
import styled from "styled-components"
import { useCookieConsents } from "@enzsft/react-cookie-consents"

import { DARK_BLUE, GREY_25, TABLET_MIN_WIDTH } from './config'

const Wrapper = styled.div`
  position: fixed;
  bottom: 0;
  box-sizing: border-box;
  width: 100vw;
  padding: 7px 16px 8px 16px;
  display: flex;
  background-color: rgba(255, 255, 255, 0.9);
  border-top: 1px solid white;
  justify-content: center;
  z-index: 10;
`

const Content = styled.div`
  font-size: 11px;
  line-height: 12px;
`

const Button = styled.button`
  outline: none;
  border: 1px solid ${DARK_BLUE};
  background-color: white;
  cursor: pointer;
  font-family: 'Open Sans', sans-serif;
  font-size: 12px;
  line-height: 12px;
  color: ${GREY_25};
  padding: 7px 15px;
  border-radius: 2px;
  font-weight: bold;
  margin-left: 16px;

  &:hover {
    border-color: ${GREY_25};
  }

  @media only screen and (max-width: ${TABLET_MIN_WIDTH}px) {
    display: block;
    margin: 0;
    margin-top: 8px;
    width: 100%;
  }
`

const CookieBanner = () => {
  const cookieConsents = useCookieConsents()

  if (cookieConsents.get().length > 0) {
    return null
  }

  return (
    <Wrapper>
      <Content>
        Wir verwenden Cookies, um dir das beste Erlebnis auf unserer
        Website zu bieten. Mit der Fortsetzung stimmst du der Verwendung
        von Cookies zu.
        <Button type="button" onClick={() => cookieConsents.add("analytics")}>
          Verstanden!
        </Button>
      </Content>
    </Wrapper>
  )
}

export default CookieBanner

