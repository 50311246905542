export const TABLET_MIN_WIDTH = 668
export const DESKTOP_MIN_WIDTH = 1024
export const WRAPPER_WIDTH = 1100

export const GREY_25 = '#3f3f3f'
export const GREY_40 = '#666666'
export const GREY_70 = '#b2b2b2'
export const GREY_90 = '#e5e5e5'
export const GREY_95 = '#f2f2f2'
export const DARK_BLUE = '#5A4CF1'
export const GREEN = '#8ECE00'
